<template>
  <v-container>
    <v-row>
      <v-col cols="12"
             md="11">
        <h1>Push Notification Performance By Offer</h1>
      </v-col>
      <v-col cols="12"
             md="1">
        <v-menu
          bottom
          left>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list rounded>
            <v-subheader>Menu</v-subheader>
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in submenu"
                :key="i"
                dense
                :to="{name: item.link}">
                <v-list-item-icon>
                  <v-icon v-text="item.icon" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text" />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <find-condition :dayInfo="today" />
    <report-list :reportData="endDaySum"
      :loadReport="reportLoading" />
    <v-btn
      v-show="reportExport"
      class="mt-5"
      color="primary"
      outlined
      :loading="reportExportLoading"
      :disabled="reportExportLoading"
      @click="exportsData">
      <template v-slot:loader>
        <span class="custom-loader">
          <v-icon light>mdi-cached</v-icon>
        </span>
      </template>
      <v-icon class="mr-2">mdi-file-export-outline</v-icon>
      Export
    </v-btn>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PushPerformanceOffer',
  components: {
    ReportList: () => import('@/components/Reports/pushPerformanceOffer.vue'),
    FindCondition: () => import('@/components/Reports/offerCondition.vue')
  },
  data() {
    return {
      submenu: [
        { text: 'Offer / Job', icon: 'mdi-message-text-clock-outline', link: 'performanceOffers' },
        { text: 'Summary', icon: 'mdi-message-processing-outline', link: 'performanceSummary' },
        { text: 'Fail Summary', icon: 'mdi-message-alert-outline', link: 'performanceSummaryFail' },
      ],
      today: {
        start_date: 0,
        start_end: 0,
        tel_type: { keys: 'ALL', value: 'A' },
        device_type: { name: 'All Platform', value: '' }
      },
      loadData: false,
      exportStatus: false,
      exportLoading: false,
      endDaySum: [],
      devices: [{ name: 'All Platform', value: '' }, { name:'Web', value: 'web' }, { name:'Native App', value: 'native' }, { name: 'Android', value: 'android' }, { name: 'iOS', value: 'ios' }]
    };
  },
  computed: {
    ...mapGetters({
      reportDataList: 'reports/GET_REPORTS',
      reportLoading: 'reports/GET_REPORT_LOADING',
      reportExport: 'reports/GET_REPORT_EXPORT',
      reportExportLoading: 'reports/GET_REPORT_EXPORT_LOADING'
    }),
  },
  beforeDestroy() {
    // eslint-disable-next-line no-undef
    eventBus.$off('findData');
  },
  async created() {
    const currentDay = new Date().getTime();
    this.today.start_date = currentDay;
    this.today.start_end = currentDay;
    
    await this.$store.dispatch('reports/ClearDataReport',[]);

    // eslint-disable-next-line no-undef
    eventBus.$on('findData', data => this.findData(data));
  },

  methods: {
    async findData() {
      // this.loadData = true;
      this.endDaySum = [];
      await this.$store.dispatch('reports/ClearDataReport',[]);
      await this.$store.dispatch('reports/GetReportPushNotificationPerformanceOffer', this.today)
        .then(() => {
          this.sumReport();
        })
    },

    async exportsData() {
      // this.exportLoading = true;
      await this.$store.dispatch('reports/exportReportPushNotificationPerformanceOffer', this.today)
        
    },

    sumReport() {
      let sum = {};

      this.reportDataList.reports.forEach(r => {
        r.pushs.forEach(o => {
          sum.failed = (sum.failed || 0) + o.failed;
          sum.notSend = (sum.notSend || 0) + o.notSend;
          sum.openedInbox = (sum.openedInbox || 0) + o.openedInbox;
          sum.openedPush = (sum.openedPush || 0) + o.openedPush;
          sum.success = (sum.success || 0) + o.success;
          sum.total = (sum.total || 0) + o.total;
        });

        // this.$store.dispatch('reports/PushSummaryEnddayOfferPerfomance', {
        //   jobId: '',
        //   jobName: 'Total End Day : ',
        //   failed: sum.failed,
        //   notSend: sum.notSend,
        //   openedInbox: sum.openedInbox,
        //   openedPush: sum.openedPush,
        //   success: sum.success,
        //   total: sum.total,
        // });

        r.pushs.push({
          jobId: '',
          jobName: 'Total End Day : ',
          failed: sum.failed,
          notSend: sum.notSend,
          openedInbox: sum.openedInbox,
          openedPush: sum.openedPush,
          success: sum.success,
          total: sum.total,
        })

        this.endDaySum.push(r);

        sum = {};
      });
    }
  }
};
</script>
